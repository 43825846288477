import React from 'react';
//import PropTypes from 'prop-types';
import * as BigCalendar from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
//import { HTML5Backend } from "react-dnd-html5-backend";
//import { DndProvider } from "react-dnd";

moment.locale('en-US');
const localizer = BigCalendar.momentLocalizer(moment)
const DragAndDropCalendar = withDragAndDrop(BigCalendar.Calendar);

/*
const myEventsList = [
  {
    id: 0,
    title: 'All Day Event very long title',
    allDay: true,
    start: new Date(2021, 2, 1),
    end: new Date(2021, 2, 1),
  },
  {
    id: 1,
    title: 'Long Event',
    start: new Date(2021, 2, 7),
    end: new Date(2021, 2, 10),
  },
]
*/


class Dnd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: []
    };

    this.moveEvent = this.moveEvent.bind(this);
    this.add_calendar_event = this.add_calendar_event.bind(this);
    this.clear = this.clear.bind(this);
  }

  clear() {
      this.setState({events : []});
  }

  add_calendar_event(event) {
     this.setState({ events : [...this.state.events, event]});
  }

  moveEvent({ event, start, end }) {
    const { events } = this.state;

    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };

    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    this.setState({
      events: nextEvents
    });
  }
  resizeEvent = (resizeType, { event, start, end }) => {
    const { events } = this.state;

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });

    this.setState({
      events: nextEvents
    });
  };

  render() {
       const {...other} = this.props;
    return (
      <DragAndDropCalendar
        {...other}
        selectable
        events={this.state.events}
        resizable
        onEventResize={this.resizeEvent}
        defaultView={BigCalendar.Views.MONTH}
        localizer={localizer}
        onEventDrop={this.moveEvent}
        startAccessor="start"
        endAccessor="end"
      />
    );
  }
}

export default Dnd;
