import React from 'react';
//import clsx from 'clsx';
import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
//import Tabs from '@material-ui/core/Tabs';
//import Tab from '@material-ui/core/Tab';
//import PhoneIcon from '@material-ui/icons/Phone';
//import FavoriteIcon from '@material-ui/icons/Favorite';
//import PersonPinIcon from '@material-ui/icons/PersonPin';
//import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
//import AppBar from '@material-ui/core/AppBar';
//import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
//import { DateTimePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
//import Paper from '@material-ui/core/Paper';
//import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
//import CommentIcon from '@material-ui/icons/Comment';
//import { DateRangePickerComponent } from "materialui-daterange-picker";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AsyncAutocomplete from './AsyncAutocomplete';
//import Dnd from './BeamlineCalendar';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import OutlinedInput from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import EditIcon from '@material-ui/icons/Edit';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import LabelledOutline from './LabelledOutline';
import Api from './Api';

import { v4 as uuidv4 } from 'uuid';


class ResourceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
             checked : [0],
             resources : [],
             selected_beamline : this.props.selected_beamline
        };

        this.checked = this.checked.bind(this);
        this.get_entry = this.get_entry.bind(this);
        this.exists = this.exists.bind(this);
        this.remove_selected = this.remove_selected.bind(this);
        this.add_entry = this.add_entry.bind(this);
        this.edit_entry = this.edit_entry.bind(this);
        this.resources = this.resources.bind(this);
        this.click_handler = this.click_handler.bind(this);
        this.beamline_changed = this.beamline_changed.bind(this);
        this.update_resources = this.update_resources.bind(this);
        this.async_update_resources = this.async_update_resources.bind(this);
    }

    beamline_changed(value) {
      console.log("selected beamline:" + value);
        this.setState({selected_beamline : value});
        this.update_resources(value);
    }

    click_handler(event, value) {
        var content = event.target.textContent;
        var search = content.indexOf(" (");
        var result = content.substring(0, search).trim();
        var output = null;
        for(var i = 0; i < this.state.resources.length; ++i) {
              if(result === this.state.resources[i].name) {
                  output = this.state.resources[i];
              }
        }
        this.props.click_callback(output["other"]);
    }

    get_entry(name) {
        for(var i = 0; i < this.state.resources.length; ++i) {
             if(name === this.state.resources[i].name) {
                 return this.state.resources[i];
             }
        }
        return null;
    }

    exists(name) {
        for(var i = 0; i < this.state.resources.length; ++i) {
             if(name === this.state.resources[i].name) {
                 return true;
             }
        }
        return false;
    }

    checked() {
        return this.state.checked;
    }

    resources() {
        return this.state.resources;
    }

    edit_entry(content) {
        if(!this.exists(content.name))
            return false;

        var new_out = []
        for(var i = 0; i < this.state.resources.length; ++i) {
             if(content.name === this.state.resources[i].name) {
                 new_out.push(content);
             } else {
                 new_out.push(this.state.resources[i]);
             }
        }
        this.setState({ resources : new_out });
    }

    add_entry(content) {
        if(this.exists(content.name))
            return false;

        var new_out = [...this.state.resources]
        for(var i = 0; i < new_out.length; ++i) {
             new_out[i]["index"] = i;
        }
        new_out.push({...content, index : this.state.resources.length});
        this.setState({ resources : new_out });
        return true;
    }

    remove_selected() {
        var new_user_lists = []
        var remove_list = []
        for(var i = 0; i < this.state.resources.length; ++i) {
             var index = this.state.checked.indexOf(this.state.resources[i].name);
             if(index === -1) {
                 new_user_lists.push(this.state.resources[i]);
             } else 
             {
                 remove_list.push(this.state.resources[i]);
             }
        }

        this.setState({checked : [0]});
        this.setState({ resources : new_user_lists });

        return remove_list;
    }

    update_resources(value) {
       (async () => {
           await this.async_update_resources(value);
       }
       )();
    }
    async async_update_resources(value) {
      console.log("updating for: " + value);
      var response = await Api.get("/api/get/resource_collections_by_beamline?beamline=" + value);
      var data = await response.json();
      //console.log(data.results);
      let results = []
      for(var i = 0; i < data.results.length; ++i) {
             results.push({
                  index : i,
                  name : data.results[i].name,
                  description : data.results[i].description,
                  other : data.results[i]
             });
      }
      this.setState({ resources : results });
    }

    componentDidMount() {
        this.update_resources(this.state.selected_beamline);
    }

    render() {
       const handleToggle = (value) => () => {
            const currentIndex = this.state.checked.indexOf(value);
            const newChecked = [...this.state.checked];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            this.setState({ checked : newChecked });
       };

      const {click_callback, ...other} = this.props;
      return (
         <List {...other}>
             {this.state.resources.map((value) => {
        
                const labelId = "checkbox-list-label-" + uuidv4();

                return (
                   <ListItem key={value.name} role={undefined} dense button onClick={handleToggle(value.name)}>
                       <ListItemIcon>
                           <Checkbox edge="start" tabIndex={-1} disableRipple />
                       </ListItemIcon>
                       <ListItemText id={labelId} primary={value.name + " (" + value.description + ")"} onClick={this.click_handler}/>
                   </ListItem>
                  );
             })}
         </List>
      );
    }
}

ResourceList.propTypes = {
    click_callback : PropTypes.any.isRequired,
    selected_beamline : PropTypes.any.isRequired,
};

class CollectionList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resources : [],
            checked : [0],
        };
        this.remove_selected = this.remove_selected.bind(this);
        this.add_entry = this.add_entry.bind(this);
        this.exists = this.exists.bind(this);
        this.checked = this.checked.bind(this);
        this.resources = this.resources.bind(this);
        this.clear = this.clear.bind(this);
        this.set_resources = this.set_resources.bind(this);
    }

    set_resources(resources) {
        this.setState({ resources : resources, checked : [0] });
    }

    clear() {
        this.setState({ resources : [], checked : [0] });
    }

    exists(name) {
        for(var i = 0; i < this.state.resources.length; ++i) {
             if(name === this.state.resources[i].name) {
                 return true;
             }
        }
        return false;
    }

    checked() {
        return this.state.checked;
    }

    resources() {
        return this.state.resources;
    }

    add_entry(content) {
        if(this.exists(content.name))
            return false;

        var new_out = [...this.state.resources]
        for(var i = 0; i < new_out.length; ++i) {
             new_out[i]["index"] = i;
        }
        new_out.push({...content, index : this.state.resources.length});
        this.setState({ resources : new_out });
        return true;
    }

    remove_selected() {
        console.log("here", this.state.resources);
        var new_user_lists = [];
        var remove_list = [];

        for(var i = 0; i < this.state.resources.length; ++i) {
             var index = this.state.checked.indexOf(this.state.resources[i].name);
             console.log(index);
             if(index === -1) {
                 new_user_lists.push(this.state.resources[i]);
             } else 
             {
                 remove_list.push(this.state.resources[i]);
             }
        }

        this.setState({checked : [0]});
        this.setState({ resources : new_user_lists });
        return remove_list;
    }

    render() {
      //const classes = useListStyles();
      const handleToggle = (value) => () => {
          const currentIndex = this.state.checked.indexOf(value);
          const newChecked = [...this.state.checked];

          if (currentIndex === -1) {
             newChecked.push(value);
          } else {
             newChecked.splice(currentIndex, 1);
          }

          this.setState({checked : newChecked});
      };

  const {...other} = this.props;
  return (
    <Box border={1}>
    <List {...other}>
      {this.state.resources.map((value) => {
        //const labelId = `checkbox-list-label-${value.index}`;
        const labelId = "checkbox-list-label-" + uuidv4();

        return (
          <ListItem key={value.name} role={undefined} dense buttn onClick={handleToggle(value.name)}>
            <ListItemIcon>
              <Checkbox edge="start" tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value.name} />
          </ListItem>
        );
      })}
    </List>
    </Box>
  );
  }
}

class ConfigureCollectionAccess extends React.Component {
    constructor(props) {
       super(props);
       this.state = {
           selected_beamline : this.props.selected_beamline,
           sub_resources : [],
           checked : [0]
       };

       this._cref = React.createRef();
       this._full_collection_list = React.createRef();

       this._collection_list = React.createRef();
       this.resource_callback = this.resource_callback.bind(this);
       this.async_resource_callback = this.async_resource_callback.bind(this);
       this.async_resource_callback_2 = this.async_resource_callback_2.bind(this);
       this.g_results = {}

       this.beamline_changed = this.beamline_changed.bind(this);
       this.create_collection = this.create_collection.bind(this);
       this.remove_collection = this.remove_collection.bind(this);

       this.refresh = this.refresh.bind(this);
       this.collection_changed_callback = this.props.collection_changed_callback;
    }

    remove_collection() {
       console.log("remove collection");

        var new_user_lists = [];
        var remove_list = [];

        for(var i = 0; i < this.state.sub_resources.length; ++i) {
             var index = this.state.checked.indexOf(this.state.sub_resources[i].name);
             if(index === -1) {
                 new_user_lists.push(this.state.sub_resources[i]);
             } else
             {
                 remove_list.push(this.state.sub_resources[i]);
             }
        }

        this.setState({ checked : [0]});
        this.setState({ sub_resources : new_user_lists });

        console.log("log:", remove_list);

        var remove_names = []
        for(var j = 0; j < remove_list.length; ++j) {
            remove_names.push(remove_list[j].name); 
        }
        console.log("log:", remove_names);

        var post_data = {
            names : remove_names,
            beamline : this.state.selected_beamline
        };

        var response = Api.post("/api/post/remove_collections", post_data);
        console.log(response);

        this._collection_list.current.clear();
        this._cref.current.clear_selection();
        document.getElementById("collection-name").value = "";
    }

    refresh() {
        this.beamline_changed(this.state.selected_beamline);   
    }

    create_collection() {
        var name = document.getElementById("collection-name").value;
        var data_resources = this._collection_list.current.resources();

        if(name.length === 0) { alert("Name not provided"); return; }
        if(data_resources.length === 0) { alert("Number of resources cannot be zero."); return; }

        var resources = [];
        for(var i = 0; i < data_resources.length; ++i) {
            resources.push(data_resources[i].name);

            if(name === data_resources[i].name) {
                alert("Unique name conflict. Beamline resources should have unique names");
                return;
            }
        }
        
        var options = this.state.sub_resources;

        var new_entry = true;

        for(var j = 0; j < options.length; ++j) {
            //console.log("CHECKING", options[j].name, name);
            if(options[j].name === name) {
                var r = window.confirm("Entry Exists. Update Collection? Press Ok to continue, Otherwise Cancel.");

                if(r === false) {
                    return;
                }
                new_entry = false;
            }
        }

        if(new_entry)  {
            name = name + " [" + uuidv4() + "]";

            var post_data = {
                name : name,
                resources : resources,
                beamline : this.state.selected_beamline
            };

            var response = Api.post("/api/post/collection", post_data);
            console.log(response);
        } else {
            var post_data = {
                name : name,
                resources : resources,
                beamline : this.state.selected_beamline
            };

            var response = Api.put("/api/put/collection", post_data);
            console.log(response);
        }

        this._collection_list.current.clear();
        document.getElementById("collection-name").value = "";

        this._cref.current.clear_selection();
        this.resource_callback(this.state.selected_beamline);
    }

    beamline_changed(value) {
        this.setState({selected_beamline : value });
        this.resource_callback(value);
        this._collection_list.current.clear();
        this._cref.current.clear_selection();
        document.getElementById("collection-name").value = "";
    }

    resource_callback(value) {
       (async () => {
            await this.async_resource_callback(value);
        })();
    }

    async async_resource_callback_2() {
        return await this.async_resource_callback(this.state.selected_beamline);
    }

    async async_resource_callback(value) {

      var response = await Api.get("/api/get/collections_by_beamline?beamline=" + value);
      var data = await response.json();

      this.g_results = {};
      let results = []
      var sub_results = [];

      for(var i = 0; i < data.results.length; ++i) {

         //if name and collection are not the same
         if(!(data.results[i].resources.length === 1 && data.results[i].name === data.results[i].resources[0])) {
             sub_results.push({
              index : i,
              name : data.results[i].name,
              resources : data.results[i].resources,
             });
         }
         results.push({
              index : i,
              name : data.results[i].name,
              resources : data.results[i].resources,
         });
         this.g_results[data.results[i].name] = data.results[i];
      }

      this.setState({sub_resources : sub_results});
      return results;
    }

    componentDidMount() {
        this.resource_callback(this.state.selected_beamline);
    }

    render() {
         const handleToggle = (value) => () => {
            const currentIndex = this.state.checked.indexOf(value);
            const newChecked = [...this.state.checked];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            this.setState({ checked : newChecked });
        };

        const add_resource = (event) => {
              var selected_value = document.getElementById("collection-resource").value;
              if(selected_value.length === 0)
                  return;
              var obj = this.g_results[selected_value];
              this._collection_list.current.add_entry(obj);
        };

        const remove_selected = (event) => {
              this._collection_list.current.remove_selected();
        };

        const click_handler = (event, value) => {
            var content = event.target.textContent;
            //this._collection_list.current.clear();
            //var output = null;
            var resource = this.g_results[content];

            if(resource == null) {
                return;
            }
            var sub_resources = []
            for(var i = 0; i < resource.resources.length; ++i) {
                var sub_resource = this.g_results[resource.resources[i]];
                if(sub_resource === undefined || sub_resource === null) {
                    console.log("undefined", resource.resources[i]);
                    continue;
                }
                sub_resources.push(sub_resource);
            }

            this._collection_list.current.set_resources(sub_resources);
            document.getElementById("collection-name").value = content;
        };

        return (
                <Grid container spacing={4}>
                    <Grid container item xs={8}>
                      <Grid container item xs={8} alignItems="stretch" direction="column">
                        <AsyncAutocomplete 
                          ref={this._cref}
                          id="collection-resource" 
                          label="Select Remote Resource" 
                          fetch_callback={this.async_resource_callback_2}
                        />
                      </Grid>

                      <Grid container item xs={4} justify="center" alignItems="stretch" direction="column">
                           <Button fullWidth variant="contained" color="primary" onClick={add_resource}>Add Resource</Button>
                      </Grid>

                      <Grid container item xs={12} alignItems="stretch" direction="column">
                        <CollectionList ref={this._collection_list} style={{ height : 300, overflow : "auto" }} />
                       <Button variant="contained" color="primary" onClick={remove_selected}>Remove Selected</Button>
                      </Grid>
                    </Grid>

                    <Grid container item xs={4} alignItems="stretch" alignContent="stretch" direction="column">
                       <LabelledOutline label="Collections">
                       <List style={{ height: 360, overflow : "auto" }} ref={this._full_collection_list}>
                          {this.state.sub_resources.map((value) => {

                           const labelId = "checkbox-list-label-" + uuidv4();

                           return (
                               <ListItem key={value.name} role={undefined} dense button onClick={click_handler}>
                                 <ListItemIcon>
                                   <Checkbox edge="start" tabIndex={-1} disableRipple onClick={handleToggle(value.name)}/>
                                 </ListItemIcon>
                                 <ListItemText id={labelId} primary={value.name} />
                               </ListItem>
                           );
                           })}
                       </List>
                           <Button fullWidth variant="contained" color="primary" onClick={this.remove_collection}>Remove Selected Collection(s)</Button>
                       </LabelledOutline>
                   </Grid>

                   <Grid container item xs={8} justify="center">
                    <TextField
                        id="collection-name"
                        label="Unique Collection Name"
                        style={{ margin: 8 }}
                        placeholder="Collection Name"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true, }}
                        variant="outlined"
                    />
                   </Grid>

                   <Grid container item xs={4} direction="row" alignItems="center">
                       <Button fullWidth variant="contained" color="primary" onClick={this.create_collection}>Create/Update Collection</Button>
                   </Grid>
                </Grid>
       );
    }
}

ConfigureCollectionAccess.propTypes = {
   selected_beamline: PropTypes.any.isRequired,
   collection_changed_callback : PropTypes.any.isRequired,
};

class ConfigureResourceAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values : { password: '', showPassword: false },
            selected_beamline : this.props.selected_beamline,
            remote_types : [
                    { value : "VNC", default_port : "5900"},
                    { value : "Windows Remote Desktop", default_port : "3389"},
                    { value : "Jupyter", default_port : "8888"},
            ],
            selected_remote_type : null
        };

        //selected_resource_type : { value : "VNC", default_port : "5900"}
        this._resource_ref = React.createRef();

        this.add_resource = this.add_resource.bind(this);
        this.remove_resource = this.remove_resource.bind(this);
        this.reset = this.reset.bind(this);
        this.collection_item_selected = this.collection_item_selected.bind(this);
        this.beamline_changed = this.beamline_changed.bind(this);
        this.return_remote_type = this.return_remote_type.bind(this);

        this.refresh = this.refresh.bind(this);
        this.resource_changed_callback = this.props.resource_changed_callback;
    }

    refresh() {
        //console.log("REFRESH CRA");
        this._resource_ref.current.beamline_changed(this.state.selected_beamline);
    }

    beamline_changed(beamline) {
        this.setState({selected_beamline : beamline });
        this._resource_ref.current.beamline_changed(beamline);
        this.reset();
    }

    componentDidMount() {
        this.reset();
    }

    reset() {
        this.setState({selected_remote_type : null})
        document.getElementById("resource-name").value = "";
        document.getElementById("resource-description").value = "";
        document.getElementById("resource-address").value = "";
        document.getElementById("resource-port").value = "";
        document.getElementById("resource-username").value = "";
        document.getElementById("resource-password").value = "";
        this.setState({ values : { password : '', showPassword : false}});
    }

    remove_resource() {
        var removed_entries = this._resource_ref.current.remove_selected();
        var removed_names = []
        for(var i = 0; i < removed_entries.length; ++i) {
            removed_names.push(removed_entries[i].name);
        }

        if(removed_names.length > 0) {
            //console.log("REMOVED NAMES", removed_names);
            var post_data = {
                 names : removed_names,
                 beamline : this.state.selected_beamline
            };

            var response = Api.post("/api/post/remove_resources", post_data);
            console.log(response); //TODO: handle response
        }
    }

    add_resource() {
        var type = document.getElementById("resource-type").value;
        var name = document.getElementById("resource-name").value;
        var description = document.getElementById("resource-description").value;
        var address = document.getElementById("resource-address").value;
        var port = document.getElementById("resource-port").value;
        var username = document.getElementById("resource-username").value;
        var password = document.getElementById("resource-password").value;
        //(type + "," + name + "," + description + ", " + address + ", " + port + ", " + username + "," + password);
        
        if(name.length === 0) { alert("Name not provided"); return; }

        //if name exists
        var new_entry = true;
        if(this._resource_ref.current.exists(name)) {
             var r = window.confirm("Resource Exists. Please press OK to Update existing entry, Otherwise Cancel."); 
             if(r === false) {
                 return;
             }

             new_entry = false;
        }

        if(new_entry) {
            if(address.length === 0) { alert("No IP address provided"); return; }
            if(type !== "Jupyter") {
              if(port.length === 0) { alert("No PORT provided"); return; }
              if(password.length === 0) { alert("No password provided"); return; }
            }
        }
        else {
            //get values from old entry
            var ge = this._resource_ref.current.get_entry(name);

            if(type !== "Jupyter") {
              if(address.length === 0) address = ge["other"]["address"];
              if(port.length === 0) port = ge["other"]["port"];
            }
        }

        //clear password entry
        document.getElementById("resource-password").value = "";
        this.setState({ values : { password : '', showPassword : false}});

        if(new_entry) //create unique id...
            name = name + " [" + uuidv4() + "]";

        var post_data = {
            type : type,
            name : name,
            description : description,
            address : address,
            port : port,
            username : username,
            password : password,
            beamline : this.state.selected_beamline
        };

        if(new_entry) {
            var response = Api.post("/api/post/resource", post_data);
            console.log(response); //TODO: address response
            delete post_data['password'];
            this._resource_ref.current.add_entry({name : name, description : description, other : post_data}); 
            this.resource_changed_callback();
        } else {
            var put_response = Api.put("/api/put/resource", post_data);
            console.log(put_response); //TODO: address response
            delete post_data['password'];
            this._resource_ref.current.edit_entry({name : name, description : description, other : post_data}); 
            this.resource_changed_callback();
        }
    }

    return_remote_type(type) {
         if(type === "VNC") return this.state.remote_types[0];
         if(type === "Windows Remote Desktop") return this.state.remote_types[1];
         if(type === "Jupyter") return this.state.remote_types[2];
         return this.state.remote_types[0];
    }

    collection_item_selected(item) {
        this.setState({selected_remote_type : this.return_remote_type(item.type)});
        //document.getElementById("resource-type").value = this.return_remote_type(item.type);
        document.getElementById("resource-name").value = item.name;
        document.getElementById("resource-description").value = item.description;
        document.getElementById("resource-address").value = item.address;
        document.getElementById("resource-port").value = item.port;
        document.getElementById("resource-username").value = item.username;
        document.getElementById("resource-password").value = "";
        this.setState({ values : { password : '', showPassword : false}});
    }

    render() {
        const handleChange = (prop) => (event) => {
            this.setState({values : { ...this.state.values, [prop]: event.target.value }});
        };

        const handleClickShowPassword = () => {
            this.setState({values : { ...this.state.values, showPassword: !this.state.values.showPassword }});
        };

        const handleMouseDownPassword = (event) => {
            event.preventDefault();
        };

        const remote_input_change = (event, value) => {
            for(var i = 0; i < this.state.remote_types.length; ++i) {
                  if(value === this.state.remote_types[i].value) {
                      document.getElementById("resource-port").value = this.state.remote_types[i].default_port;
                      //this.setState({selected_remote_type : this.state.remote_types[i].value});
                  }
             }
        }

        const remote_change = (event, value) => {
            //console.log("REMOTE CHANGE", value);
            this.setState({selected_remote_type : value});
        }
        return (<form>
             <Grid container item spacing={3}>
               <Grid container item spacing={3} xs={8}>
                <Grid item xs={10}>
                    <Autocomplete id="resource-type" 
                        fullWidth
                        label="Select Remote Protocol Type" 
                        value={this.state.selected_remote_type}
                        options={this.state.remote_types}
                        onChange={remote_change}
                        onInputChange={remote_input_change}
                        getOptionLabel={(option) => option.value}
                        renderInput={(params) => <TextField {...params} label="Remote Protocol" variant="outlined" />}
                    />
                </Grid>
                <Grid container item xs={2} alignItems="stretch" direction="column">
                   <IconButton onClick={(e) => this.reset()}>
                       <RotateLeftIcon />
                   </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="resource-name"
                        label="Resource"
                        style={{ margin: 8 }}
                        placeholder="Enter Unique Resource Name"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true, }}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="resource-description"
                        label="Description"
                        style={{ margin: 8 }}
                        placeholder="Enter Description"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true, }}
                        variant="outlined"
                     />
                 </Grid>

                <Grid item xs={8}>
                    <TextField
                        id="resource-address"
                        label="IP Address / Hostname"
                        style={{ margin: 8 }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true, }}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        id="resource-port"
                        label="Port"
                        style={{ margin: 8 }}
                        placeholder="Port"
                        helperText="Defaults (VNC: 5900, RDP: 3389, NX: 4000)"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true, }}
                        variant="outlined"
                    />
                </Grid>
               </Grid>

               <Grid item xs={4}>
                  <LabelledOutline label="Resources">
                      <ResourceList ref={this._resource_ref} style={{ height : 400, overflow : 'auto' }} click_callback={this.collection_item_selected} selected_beamline={this.props.selected_beamline}/>
                      <Button fullWidth variant="contained" color="primary" onClick={this.remove_resource}> Remove Selected Resource(s) </Button>
                  </LabelledOutline>
               </Grid>

               <Grid container item xs={12} spacing={3}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        id="resource-username"
                        label="Username (can be blank)"
                        style={{ margin: 8 }}
                        placeholder="Username"
                        InputLabelProps={{ shrink: true, }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                   <InputLabel htmlFor="standard-adornment-password">Remote Password</InputLabel>
                   <OutlinedInput
                      fullWidth
                      id="resource-password"
                      autoComplete="new-password"
                      type={this.state.values.showPassword ? 'text' : 'password'}
                      value={this.state.values.password}
                      onChange={handleChange('password')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          >
                          {this.state.values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                   />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                   <Button fullWidth variant="contained" color="primary" onClick={this.add_resource}> Create/Update Resource </Button>
                </Grid>
               </Grid>
             </Grid>
          </form>);
    }
}

ConfigureResourceAccess.propTypes = {
   selected_beamline : PropTypes.any.isRequired,
   resource_changed_callback : PropTypes.any.isRequired,
};

class ConfigureRemoteAccess extends React.Component {
      constructor(props) {
          super(props);
          const {beamlines} = props;
          this.state = {
              selected_beamline : beamlines[0],
          };
      
          this.cra = React.createRef();
          this.cca = React.createRef();
      }

      render() {
        const change_beamline = (_, value) => {
            console.log("changed", value);
            this.setState({ selected_beamline : value });
            this.cra.current.beamline_changed(value);
            this.cca.current.beamline_changed(value);
        };
  
        const resource_changed = () => {
          //this.cca.current.refresh();
        };

        const collection_changed = () => {
          //this.cra.current.refresh();
        };

        return (
        <div>
            <Grid container item xs={12} spacing={3} style={{ maxWidth : 1000, overflow : "auto" }}>
            <Grid container item xs={12}>
            <Autocomplete id="resource-beamline" 
               fullWidth
               label="Select Beamlines" 
               defaultValue={this.props.beamlines[0]}
               options={this.props.beamlines}
               onChange={change_beamline}
               getOptionLabel={(option) => option}
               renderInput={(params) => <TextField {...params} label="Beamline" variant="outlined" />}
             />
            </Grid>

            <Grid container item xs={12}>
            <LabelledOutline label="Add New Resource">
                <ConfigureResourceAccess ref={this.cra} selected_beamline={this.state.selected_beamline} resource_changed_callback={resource_changed}/>
            </LabelledOutline>
            </Grid>

            <Grid container item xs={12}>
            <LabelledOutline label="Add New Collection"> 
                <ConfigureCollectionAccess ref={this.cca} selected_beamline={this.state.selected_beamline} collection_changed_callback={collection_changed}/>
            </LabelledOutline>
             </Grid>
           </Grid>
        </div>);
      }
}

ConfigureRemoteAccess.propTypes = {
    beamlines: PropTypes.any.isRequired
}

export default ConfigureRemoteAccess;

