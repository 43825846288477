import React from "react";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/core/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

class AsyncAutocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open : false,
            options : [],
            active : false,
            option : null,
            needs_refresh : false,
        };
      this.open_trigger = this.open_trigger.bind(this);
      this.input_changed = this.input_changed.bind(this);
      this.value = "A";

      this.options = this.options.bind(this);
      this._auto_complete = React.createRef();
    }

    clear_selection() {
        this.setState({ option : null });
        this.setState({ open : false });
        //this.value = "A";
        //console.log(this._auto_complete.current);
        //console.log(this.classes);
    }

    options() {
        return this.state.options;
    }

    open_trigger() {
          this.setState({open : true, active : true});
          this.setState({ options : [] });

           (async () => {
               var response = await this.props.fetch_callback(this.value);
               if (response != null) {
                   
                   this.setState({ options : response });
                   this.setState({active : false});
               }
           })();
    }

    input_changed(event: object, value: string, reason: string) {
          if ( reason !== "reset" ) {
              this.value = value;
              this.open_trigger();
          }
    }

    render() {
        const {label, fetch_callback, ...other} = this.props;

        const change = (_, value) => {this.setState({option : value})};
        return (

            <Autocomplete
              {...other}
              ref={this._auto_complete}
              value={this.state.option}
              open={this.state.open}
              onOpen={this.open_trigger}
              onClose={() => { this.setState({open: false}); }}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name }
              options={this.state.options}
              loading={this.state.active}
              onChange={change}
              onInputChange={this.input_changed}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      label={this.props.label}
                      variant="outlined"
                      InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                          <React.Fragment>
                              {this.state.active ? (
                                  <CircularProgress color="inherit" size={20} />
                              ) : null}
                             {params.InputProps.endAdornment}
                         </React.Fragment>
                         ),
                      }}
                  />
              )}
            />
        );
    }
}

export default AsyncAutocomplete;
AsyncAutocomplete.propTypes = {
  label: PropTypes.any.isRequired,
  fetch_callback: PropTypes.any.isRequired,
};

