import './App.css';
import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import AppBar from '@material-ui/core/AppBar';
import Api from './Api';
import TabPanel from './TabPanel';

import User from './User';
import ScheduleUsers from './ScheduleUsers';
import ConfigureRemoteAccess from './ConfigureRemoteAccess';
import Iframe from 'react-iframe';

function RedirectToJupyter() {
   var old_token = localStorage.getItem("token");
   if(old_token == null) {
       return (<div>Nothing here...</div>);
   }

   var url = "https://bl832viz5x.als.lbl.gov:8443/?token=" + old_token;
   //url = "https://www.youtube.com/embed/xDMP3i36naA";
   return (window.open("https://bl832viz5x.als.lbl.gov:8443/?token=" + old_token));
   /*
   return (<div>
        <Iframe url={url}
        width="1000px"
        height="1000px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/></div>);
   */
}

function ErrorPage() {
    //var errorHTML = "<p> Not authenticated. Redirecting... </p>window.location.replace(\"https://alshub.als.lbl.gov\")";
    //return (<div dangerouslySetInnerHTML={{ __html: errorHTML }} />
    //        window.location.replace("https://alshub.als.lbl.gov"));

   return (window.location.replace("https://alshub.als.lbl.gov"));
   // return (<div></div>);
}
/*
const useStyles = makeStyles((theme) => ({
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));
*/
MainTabs.propTypes = {
  uname: PropTypes.any.isRequired,
  ubeamlines: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function MainTabs(props) {
  const { uname, ubeamlines } = props;
  //const classes = useStyles();
  const [value, setValue] = React.useState(0);

  //var schedule_tab = React.createRef();
  //var configure_tab = React.createRef();


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //is manager if role is manager or if role is admin
  var isManager = ubeamlines.length > 0;

  var counter = 0;

  if(isManager) {
    counter = 2;
  }

  var isJupyter = 0;
  for(let i = 0; i < ubeamlines.length; ++i) {
      if(ubeamlines[i] === "8.3.2") {
          isJupyter = 1;
      }
  }

  return (
    <div>
      <Tabs orientation="horizontal" variant="scrollable" value={value}
        onChange={handleChange}
      >
        <Tab style={{ textTransform: "none", fontSize: 16, fontWeight: "bold", whiteSpace: 'pre-line'}} label={"Access Resources For\n" + uname} {...a11yProps(0)} />
        { isManager ?  <Tab style={{ textTransform: "none", fontSize: 16, fontWeight: "bold", whiteSpace: 'pre-line' }} label={"Schedule\nAccess"} {...a11yProps(1)} /> : '' }

        { isManager ?  <Tab label={"Configure\nRemote Resources"}  style={{ textTransform: "none", fontSize: 16, fontWeight: "bold", whiteSpace: 'pre-line' }} {...a11yProps(2)} /> : '' }
      </Tabs>

      <TabPanel value={value} index={0}> <User /> </TabPanel>
      {isManager ? <TabPanel value={value} index={1}> <ScheduleUsers beamlines={ubeamlines} /> </TabPanel> : '' }
      {isManager ? <TabPanel value={value} index={2}> <ConfigureRemoteAccess beamlines={ubeamlines} /> </TabPanel> : '' }
      {isJupyter === 1 ? <TabPanel value={value} index={3-counter}> <div>Check New Link...</div> </TabPanel> : '' }

    </div>
  );
}

class App extends React.Component {
  constructor(props) {
     super(props);
     this.state = {
         name: "",
         beamlines: [],
         success: true
     };
  }

  componentDidMount() {
     var old_token = localStorage.getItem("token");

     const queryString = window.location.search;
     const urlParams = new URLSearchParams(queryString);
     var token = urlParams.get("token");
     //console.log("TOKEN:" + token);

     localStorage.setItem("token", token);

     if(token == null && old_token !== "null") {
         token = old_token;
     }

     if(token == null) {
        this.setState({ success : false });
     }

     window.history.replaceState({}, document.title, "/");

     Api.get("/api/get/user_data").then(res => res.json()).then(data => {
         //console.log(data);
         if (data.name === "Invalid Operation") {
            this.setState({ success : false });
         }
         else {
            //console.log(data);
            this.setState({name : data.name, beamlines: data.beamlines});
         }
     });

     return {
        name : "",
        error : false
     };
  }

  render() {
    document.title = 'Remote ALS';
var link = document.querySelector("link[rel~='icon']");
if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
}
link.href = 'https://alshub.als.lbl.gov/lbnlicon.ico';
    return ( <div> {this.state.success ? <MainTabs uname={this.state.name} ubeamlines={this.state.beamlines}/> : <ErrorPage />}</div>);
  }
}

export default App;
